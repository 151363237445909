import 'core-js/modules/es6.weak-set';

import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../styles/carousel.scss';

class IndexPage extends React.Component {

  constructor (props) {
    super(props);
    this.carouselRef = React.createRef();

    this.state = {
      currentSlide: 0 + 2,
      slidesData: [],
    };
  }

  componentDidMount () {
    const slidesData = this.props.data.allContentfulCarouselContent.edges.slice()
      .sort(() => Math.random() - 0.5)
    ;

    this.setState(state => ({ slidesData: slidesData }));
  }

  onAfterSlideChange = (previousSlide, { currentSlide }) => {
    this.setState({
      currentSlide: currentSlide,
    });
  }

  render() {
    const slides = this.state.slidesData
      .map((edge, index) => (<Slide
          key={edge.node.id}
          {...edge.node}
        />)
      )
    ;

    const carouselSettings = {
      ref: this.carouselRef,
      responsive: {
        mobile: {
          breakpoint: { max: 9999, min: 0 },
          items: 1,
        },
      },
      draggable: false,
      swipable: false,
      arrows: false,
      showDots: false,
      infinite: true,
      autoPlay: true,
      autoPlaySpeed: 6000,
      customTransition: 'all 700ms linear',
      transitionDuration: 700,
      afterChange: this.onAfterSlideChange,
    };

    return <div className="viewportFlex">
      <Layout>
        <SEO title="Home" keywords={[]} />
        <Carousel
          {...carouselSettings}
          >
          {slides}
        </Carousel>
      </Layout>
    </div>
  }
}

class Slide extends React.Component {
  constructor (props) {
    super(props);
  }

  render() {
    const { id, textColor, intro, introPlain, image } = this.props;

    return <div className={`carouselContent carouselContent--is-${textColor}`}>
      {!!image && <Img {...image} />}
      <div className="carouselContentIntro">
        <div className="carouselContentIntroInnerContainer">
          {!!intro && <Html {...intro.childMarkdownRemark} />}
        </div>
      </div>
    </div>
  }
}

const Html = ({ html }) => (
	<div
		className="html"
	  dangerouslySetInnerHTML={{
	    __html: html,
	  }}
	/>
);

export const pageQuery = graphql`
  query {
    allContentfulCarouselContent(
      filter: { type: { eq: "Homepage" } },
      sort: { fields: [createdAt] order: ASC }
      ) {
      edges {
        node {
          id
          textColor
          intro {
            childMarkdownRemark {
              html
            }
          }
          introPlain: intro {
            intro
          }
          image {
            id
            fluid(maxWidth: 1440, toFormat: JPG, quality: 75) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`
export default IndexPage
